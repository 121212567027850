import React from 'react';
import styled from 'styled-components';

const PageContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #3e7568; // Add background color
`;

const NewsletterTitle = styled.h1 `
  font-size: 36px;
  margin-bottom: 30px;
  color: #fac65d;
  font-family: 'Capriola', Arial, sans-serif;
`;

const NewsletterContainer = styled.div `
  display: flex;
  flex-direction: row;
  align-items: top;
  justify-content: center;
  padding: 1rem;
  min-height: 50;
  gap: 2rem;
  width: 95%; // Add full width
`;

const NewsletterContent = styled.div `
  background-color: #f3eac0;
  border: 2px solid #3e7568;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  flex: 1; // Make newsletter content take equal width
`;

const SectionTitle = styled.h2 `
  margin-bottom: 20px;
  font-family: 'Capriola', Arial, sans-serif;
  color: #3e7568;
`;

const Paragraph = styled.p `
  margin-bottom: 20px;
  font-family: 'Capriola', Arial, sans-serif;
  color: #3e7568;
`;

const MerchLink = styled.a `
  display: inline-block;
  background-color: #1e2640;
  color: #f3eac0;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  margin-bottom: 30px;
  font-family: 'Capriola', Arial, sans-serif;
`;

const SignUpLink = styled.a `
  display: inline-block;
  background-color: #1e2640;
  color: #f3eac0;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-family: 'Capriola', Arial, sans-serif;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;



function Newsletter() {
    return ( < PageContainer >
            <
            NewsletterTitle > MESA Monthly Newsletter < /NewsletterTitle><
            NewsletterContainer >

            { /* First story */ } <
            NewsletterContent >
            <
            SectionTitle > Welcome to our first MESA Newsletter! < /SectionTitle> <
            Paragraph >
            We held a movie night and watched Amreeka
            for our call out of the spring semester.Don 't worry if you
            couldn 't make it, we have plenty more events coming up. < /
            Paragraph > <
            Paragraph >
            Recently, we had our karaoke night and it was a big hit!Many songs were sung in many different languages.Thank you to everyone who participated and came
            for the vibes: ) <
        /Paragraph> <
    Paragraph >
        We have merch!Order yours now: ) Sweaters are $35(with a free tote bag!!!) <
    /Paragraph> <
MerchLink href = "https://forms.gle/MV71mVxGHjsFn3WR8" > Merch Link < /MerchLink> <
Paragraph >
    Make sure to follow us to keep track of upcoming events and news!
    <
    /Paragraph> < /
NewsletterContent >

    { /* Second story */ } <
    NewsletterContent >
    <
    SectionTitle > Cultural Night is only ONE WEEK AWAY < /SectionTitle> <
Paragraph >
    Don 't forget: This is a CULTURAL NIGHT. Dress up in your traditional clothing and show off off who you are :)
Thinking of walking the fashion show but don 't know what to expect? Watch the video below! < /
Paragraph > <
    Paragraph >
    We would also love you volunteer to showcase your countries.If interested, please fill out the RSVP and
let us
know what country you would like to do . <
    /Paragraph>  < /
NewsletterContent > <
    /NewsletterContainer> <
SignUpLink href = "https://iu.us10.list-manage.com/subscribe?u=6bc0ae08f5003054f427a010f&id=3a5e309bf6" > Sign up to get notified of new stories < /SignUpLink> < /
PageContainer >
);
}

export default Newsletter;