import React from 'react';
import styled from 'styled-components';

const BoardMembersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #3e7568;
  min-height: 100vh; 
`;

const BoardMembersTitle = styled.h1`
  font-size: 36px;
  margin-bottom: 30px;
  color: #fac65d;
  font-family: 'Capriola', Arial, sans-serif;
`;

const BoardList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  padding: 0;
`;

const MemberCard = styled.li`
  background-color: #f3eac0;
  border-radius: 5px;
  padding: 10px;
  margin: 35px;
  width: 300px;
  height: 300px;
  text-align: center;
`;

const MemberImage = styled.img`
  width: 300px;
  height: 300px;
  object-fit: cover;
  margin-bottom: 10px;
`;

const MemberName = styled.h3`
  margin: 0;
  margin-bottom: 0px;
  color: #fac65d;
`;

const MemberPosition = styled.p`
  margin: 0;
  font-weight: bold;
  color: #fff;
`;

function BoardMembers() {
    const members = [
        { name: 'Yaqoub', position: 'President', img: 'yaqoub.png' },
        { name: 'Maya', position: 'Vice President', img: 'maya.png' },
        { name: 'Hamza', position: 'Secretary', img: 'hamza.png' },
        { name: 'Rahaf', position: 'Membership Coordinator', img: 'rahaf.png' },
        { name: 'Wadah', position: 'Outreach', img: 'wadah.png' },
        { name: 'Abdel-Aziz', position: 'Reservationist', img: 'abdel.png' },
        { name: 'Damen', position: 'USG', img: 'damen.png' },
        { name: 'Kunafa', position: 'Treasurer', img: 'kunafa.png' },
    ];

    return (
        <BoardMembersContainer>
            <BoardMembersTitle>Board Members</BoardMembersTitle>
            <BoardList>
                {members.map((member, index) => (
                    <MemberCard key={index}>
                        <MemberImage src={`${process.env.PUBLIC_URL}/images/${member.img}`} alt={member.name} />
                        <MemberName>{member.name}</MemberName>
                        <MemberPosition>{member.position}</MemberPosition>
                    </MemberCard>
                ))}
            </BoardList>
        </BoardMembersContainer>
    );
}

export default BoardMembers;
