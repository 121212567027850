import React from 'react';
import styled from 'styled-components';
import Event from './Event';

const PageContainer = styled.div `
  background-color: #3e7568;
  color: #fac65d;
  min-height: 100vh;
  padding-top: 2px; // Add padding to fill the gap
  font-size: 26px;
  font-family: 'Capriola', Arial, sans-serif;
`;

const Container = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2px;
`;

const EventsGrid = styled.div `
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 50px;
  padding: 0 20px; // Add padding to the sides

  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr)); // Increase the minimum width for larger screens
  }
`;
const SideContent = styled.div `
  flex: 1;
  margin-left: 0px;
`;

function Events() {
    const events = [{
            title: 'Culture Night',
            description: 'Our biggest event of the year, the goal of this event was to represent countries in the greater middle eastern and bring awareness to the culture and attire.',
            images: [
                `${process.env.PUBLIC_URL}/images/cn1.jpg`,
                `${process.env.PUBLIC_URL}/images/cn2.jpg`,
                `${process.env.PUBLIC_URL}/images/cn3.jpg`,
                `${process.env.PUBLIC_URL}/images/cn4.jpg`,
                `${process.env.PUBLIC_URL}/images/cn5.jpg`,
            ],
            videos: [],
        },
        {
            title: 'Karaoke Night',
            description: 'This event brought students together to enjoy an eventful day with karaoke and many games. Whether it was singing classic Arabic songs or dancing to the latest bangers, everyone had a great time.',
            images: [`${process.env.PUBLIC_URL}/images/k1.jpg`],
            videos: [
                `${process.env.PUBLIC_URL}/videos/v1.mp4`,
                `${process.env.PUBLIC_URL}/videos/v2.mp4`,
                `${process.env.PUBLIC_URL}/videos/v3.mp4`,
                `${process.env.PUBLIC_URL}/videos/v4.mp4`,
                `${process.env.PUBLIC_URL}/videos/v5.mp4`,
                `${process.env.PUBLIC_URL}/videos/v6.mp4`,
            ],
        },

        {
            title: 'Arabs Giving',
            description: 'For this event we wanted to showcase the rich diversity of cuisine and cultural traditions of the greater Middle East, with a focus on the unique flavors and dishes that are beloved in the region. ',
            images: [
                `${process.env.PUBLIC_URL}/images/a1.jpg`,
                `${process.env.PUBLIC_URL}/images/a2.jpg`,
                `${process.env.PUBLIC_URL}/images/a3.jpg`,
                `${process.env.PUBLIC_URL}/images/a4.jpg`,
                `${process.env.PUBLIC_URL}/images/a5.jpg`,
                `${process.env.PUBLIC_URL}/images/a6.jpg`,
                `${process.env.PUBLIC_URL}/images/a7.jpg`,
            ],
            videos: [],
        },










        {
            title: 'Dabka Night',
            description: 'At the event, we taught attendees how to perform the traditional Middle Eastern dance known as dabka.Our skilled instructors broke down the steps and provided guidance to help everyone learn the dance.',
            images: [`${process.env.PUBLIC_URL}/images/dg.jpg`],
            videos: [
                `${process.env.PUBLIC_URL}/videos/d1.mp4`,
                `${process.env.PUBLIC_URL}/videos/d3.mp4`,
                `${process.env.PUBLIC_URL}/videos/d4.mp4`,
                `${process.env.PUBLIC_URL}/videos/d5.mp4`,
                `${process.env.PUBLIC_URL}/videos/d6.mp4`,
            ],
        },
    ];


    return ( <
        PageContainer >
        <
        h1 > Events < /h1> <
        Container >
        <
        EventsGrid > {
            events.map((event, index) => ( <
                Event key = { index } {...event }
                />
            ))
        } <
        /EventsGrid> <
        SideContent / >
        <
        /Container> < /
        PageContainer >
    );
}

export default Events;