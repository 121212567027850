import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import styled from 'styled-components';
import BoardMembers from './pages/BoardMembers';
import Newsletter from './pages/Newsletter';
import Events from './pages/Events';
import './App.css';

const HomePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0rem;
  background-color: #3e7568;
`;

const HomePageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  text-align: center;
  background-color: #3e7568;
  padding: 2rem;
  border-radius: 3px;
`;

const Container = styled.div`
  font-family: 'Capriola', Arial, sans-serif;
  background-color: #3e7568;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 30px;
`;

const Logo = styled.img`
  max-width: 150px;
  height: auto;
  display: inline-block;
`;

const AtMesa = styled.div`
  font-family: 'Capriola', sans-serif;
  font-size: 1.5em;
  color: #fac65d;
  text-align: center;
  
`;

const Title = styled.h1`
  font-size: 30px;
  margin-bottom: 30px;
  color: #fac65d;
`;

const LinkBox = styled.a`
  display: flex;
  align-items: center;
  background-color: #1e2640;
  padding: 15px;
  border-radius: 5px;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  color: #f3eac0;
  margin-bottom: 15px;

  &:hover {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  }
`;

const Thumbnail = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 15px;
  border-radius: 5px;
  object-fit: cover;
`;

const LinkText = styled.span`
  font-family: 'Capriola', sans-serif;
  font-size: 1.2em;
  color: #f3eac0;
  margin-left: 10px;
  display: inline-block;
  width: calc(100% - 60px);
  text-align: center;
`;

const Nav = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap;
  
  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin-right: 20px;
    
  }

  a {
    color: #f3eac0;
    text-decoration: none;
    font-size: 0.9rem;
    font-weight: bold;
    padding: 3px;
    border-radius: 3px;

    &:hover {
      background-color: #f3eac0;
      color: #1e2640;
    }
  }

  @media (min-width: 768px) {
    a {
      font-size: 1.4rem;
    }
  }

  @media (min-width: 992px) {
    a {
      font-size: 1.6rem;
    }
  }
`;

function App() {
    return (
        <Router>
            <div className="App">
                <header className="App-header">
                    <Nav>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/board-members">Board Members</Link></li>
                            <li><Link to="/newsletter">Newsletter</Link></li>
                            <li><Link to="/events">Events</Link></li>
                        </ul>
                    </Nav>
                </header>
                <main>
                    <Routes>
                        <Route path="/" element={
                            <HomePageContainer>
                                <HomePageContent>
                                    <Container>
                                        <Header>
                                            <Logo src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="Logo" />
                                            <AtMesa>@mesaiupui</AtMesa>
                                        </Header>
                                        <Title>Shedding light on all things related to the Greater Middle East Region at IUPUI</Title>
                                        
                                        <LinkBox href="https://thespot.iupui.edu/organization/mesa">
                                            <Thumbnail src={`${process.env.PUBLIC_URL}/images/spot.png`} alt="Thumbnail" />
                                            <LinkText>Become a Member - TheSpot</LinkText>
                                        </LinkBox>
                                        <LinkBox href="https://islamic-relief.org/">
                                            <Thumbnail src={`${process.env.PUBLIC_URL}/images/isu.jpg`} alt="Thumbnail" />
                                            <LinkText>Morocco + Libya relief | Islamic-Relief</LinkText>
                                        </LinkBox>
                                        <LinkBox href="https://www.zakat.org/">
                                            <Thumbnail src={`${process.env.PUBLIC_URL}/images/earth.png`} alt="Thumbnail" />
                                            <LinkText>Morocco + Libya relief | Zakat</LinkText>
                                        </LinkBox>
                                        <LinkBox href="https://instagram.com/mesaiupui">
                                            <Thumbnail src={`${process.env.PUBLIC_URL}/images/insta.png`} alt="Thumbnail" />
                                            <LinkText>Instagram</LinkText>
                                        </LinkBox>
                                        <LinkBox href="https://forms.gle/MV71mVxGHjsFn3WR8">
                                            <Thumbnail src={`${process.env.PUBLIC_URL}/images/mesa.png`} alt="Thumbnail" />
                                            <LinkText>Mesa Crewneck </LinkText>
                                        </LinkBox>
                                        <LinkBox href="http://eepurl.com/ij4FUz">
                                            <Thumbnail src={`${process.env.PUBLIC_URL}/images/news.PNG`} alt="Thumbnail" />
                                            <LinkText>News Letter Signup</LinkText>
                                        </LinkBox>
                                      
                                        <LinkBox href="https://groupme.com/join_group/89426362/s5pZUMgG">
                                            <Thumbnail src={`${process.env.PUBLIC_URL}/images/groupme.png`} alt="Thumbnail" />
                                            <LinkText>GroupMe</LinkText>
                                        </LinkBox>
                                        <LinkBox href="https://www.tiktok.com/@mesaiupui">
                                            <Thumbnail src={`${process.env.PUBLIC_URL}/images/tiktok.png`} alt="Thumbnail" />
                                            <LinkText>Tiktok</LinkText>
                                        </LinkBox>
                                    </Container>
                                </HomePageContent>
                            </HomePageContainer>
                        } />
                        <Route path="/board-members" element={<BoardMembers />} />
                        <Route path="/newsletter" element={<Newsletter />} />
                        <Route path="/events" element={<Events />} />
                    </Routes>
                </main>
            </div>
        </Router>
    );
}

export default App;
