import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const EventContainer = styled.div `
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 1rem;
  position: relative;
  margin-bottom: 3px;
  background-color: #f3eac0;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-top: 50px; // Add space for the event title
`;

const EventTitle = styled.h3 `
  margin: 0 0 10px;
  font-family: 'Capriola', Arial, sans-serif;
  color: #fff;
  position: absolute;
  top: -60px; // Increase the space between the event title and container
  left: 50%;
  transform: translateX(-50%);
  background-color: #3e7568;
  padding: 5px;
  border-radius: 5px;
  font-size: 26px; // Decrease the font size
`;

const PageTitle = styled.h1 `
  font-family: 'Capriola', Arial, sans-serif;
  color: #f3eac0;
  text-align: center;
  margin-top: 30px;
  font-size: 36px; // Increase the font size for the page title
   font-size: 30px;
`;


const EventDescription = styled.p `
  margin: 0 0 15px;
  font-family: 'Capriola', Arial, sans-serif;
  color: #3e7568; // Change the color of the event description
  font-size: 15px;
`;

const ImageContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
`;

const StyledImage = styled.img `
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
`;

const StyledVideo = styled.video `
  object-fit: contain;
  width: 100%;
  height: 100%;
`;

// Custom arrow components

const Arrow = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 10%;
  cursor: pointer;
  z-index: 10;
  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
`;

const NextArrow = styled(Arrow)
`
  position: absolute;
  right: -15px;
  top: calc(50% - 15px);
`;

const PrevArrow = styled(Arrow)
`
  position: absolute;
  left: -15px;
  top: calc(50% - 15px);
`;

function CustomNextArrow(props) {
    const { onClick } = props;
    return <NextArrow onClick = { onClick } > › < /NextArrow>;
}

function CustomPrevArrow(props) {
    const { onClick } = props;
    return <PrevArrow onClick = { onClick } > ‹ < /PrevArrow>;
}

function Event({ title, description, images, videos }) {
    const sliderSettings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        arrows: true,
        adaptiveHeight: true,
        nextArrow: < CustomNextArrow / > ,
        prevArrow: < CustomPrevArrow / > ,
    };

    return ( <
        EventContainer >
        <
        EventTitle > { title } < /EventTitle> <
        EventDescription > { description } < /EventDescription> <
        Slider {...sliderSettings } > {
            images.map((imgSrc, index) => ( <
                ImageContainer key = { index } >
                <
                StyledImage src = { imgSrc }
                alt = { `Image ${index + 1} for ${title}` }
                /> < /
                ImageContainer >
            ))
        } {
            videos.map((videoSrc, index) => ( <
                ImageContainer key = { index } >
                <
                StyledVideo src = { videoSrc }
                alt = { `Video ${index + 1} for ${title}` }
                controls / >
                <
                /ImageContainer>
            ))
        } <
        /Slider> < /
        EventContainer >
    );
}

export default Event;